import React from "react"
import { Layout2022Q3 } from "@layout"
import { HeroContact2022Q3 } from "@components/hero"
import { MainBlockAdvertise } from "@components/main-blocks"
import SEO from "@components/seo"

const Advertise = () => (
  <Layout2022Q3
    backgroundImage="animated"
    hero={
      <HeroContact2022Q3
        title="Advertise with us!"
        description="Let us know about advertising opportunities."
      />
    }
  >
    <SEO title="Advertise with us" />
    <MainBlockAdvertise />
  </Layout2022Q3>
)

export default Advertise
